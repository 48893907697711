import React from "react";
import { Link, graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import Img from "gatsby-image";
import productinnovation from "../../images/product-innovation.jpg";
import dedicatedteams from "../../images/dedicated-teams-optimized.jpg";
import mvpfasttrack from "../../images/mvp-fasttrack-optimized.jpg";
import Nandika from "../../images/Nandika-compressed.jpg";
import comment from "../../images/comment.png";
import ContactUs from "../../components/ContactUs";

const Productinnovation = (props) => {
  return (
    <Layout bodyClass="page-services">
      <Seo
        title="Product Innovation"
        description="Product Innovation and Application Lifecycle Management (ALM)
                are a significant part of iTelaSoft's key offerings. We have
                assisted a great variety of organisations, from start-ups to
                large enterprises, in their product lifecycle journey. Currently
                we support a number of customers around the world, including
                Australia, Europe, and North America."
      />
      <div className="intro intro-banner">
        <div className="intro-banner-img" id="up">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
            className="hero-banner"
          />
        </div>
        <div className="container">
          <div className="row hero-section">
            <div className="col-12 wrap-page-title">
              <h1 className="page-title">Product Innovation</h1>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-6 section-2">
        <div className="row">
          <div className="col-12 mt-4 text-center">
            <div className="sub-title-one">Software Product Management</div>
            <hr />
            <div className="discription">
              <p>
                Product Innovation and Application Lifecycle Management (ALM)
                are a significant part of iTelaSoft's key offerings. We have
                assisted a great variety of organisations, from start-ups to
                large enterprises, in their product lifecycle journey. Currently
                we support a number of customers around the world, including
                Australia, Europe, and North America.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="product innovation" className="img-inno" src={productinnovation} />
          </div>
          <div className="col-12 col-md-6">
            <div className="space">
              <h4>Product Innovation</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  Product innovation demands a blend of competencies including
                  ideation, business analysis, user journey mapping, system
                  design, integration techniques and data security. iTelaSoft is
                  a one-stop shop for converting your innovative business ideas
                  into a viable product. We work closely with our customers to
                  expand ideas, identify market disruptive goals, enrich them
                  and perform initial research in order to create winning
                  product formulas. Our diverse experience in domains such as
                  Education, Fintech, Telco and Embedded Systems, along with
                  emerging technologies like blockchain, chatbots and prediction
                  engines, helps us to innovate great products on behalf of our
                  customers.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 p-0 order-md-first order-last">
            <div className="space">
              <h4>MVP Fast-Track</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  One of the deciding factors in product development is how fast
                  the Minimum Viable Product (MVP) can be released into the
                  market. In this critical phase, the product must be usable,
                  engaging, secure and reliable and affordable. iTelaSoft works
                  diligently with our clients to understand key success criteria
                  and work closely to fast-track the product implementation. We
                  execute the MVP phase in a flexible and agile manner, so the
                  product concepts and value creation are continuously
                  fine-tuned while responding to feedback from customer testing.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="mvp fast track" className="img-inno" src={mvpfasttrack} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="Nandika" className="img-inno" src={Nandika} />
          </div>
          <div className="col-12 col-md-6 p-0">
            <div className="space">
              <h4>Long-Term Support</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  Once a product is out in the market, it needs attention to
                  sustain and evolve. The application host environments have to
                  be monitored for resource consumption, up time, performance
                  and security. To be competitive in the market, product should
                  evolve with features and capabilities. Maintaining the
                  viability of the product while keeping the costs down is both
                  a science and an art. iTelaSoft provides this long-term
                  development and maintenance support for many of our clients.
                </p>
              </div>
              <div className="btn btn-outline-primary mt-3">
                <a
                  href="/services/managed-services/"
                  aria-label="managed-services"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 p-lg-0 order-md-first order-last">
            <div className="space">
              <h4>Dedicated Teams</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  Some of our customers prefer a dedicated team, acting as part
                  of their organisation. In certain product scenarios, this
                  makes a lot of sense, especially where branding, regulations,
                  and specialised knowledge retention are concerned. A
                  significant component of our service involves, providing
                  dedicated teams for product organizations or Independent
                  Software Vendors (ISV). With our decade's worth of experience
                  in product development and software services, iTelaSoft
                  provides dedicated teams to extend your organisation.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="dedicated teams" className="img-inno" src={dedicatedteams} />
          </div>
        </div>
        <div className="row wrap-contact-us">
          <ContactUs />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "productinnovation-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default Productinnovation;
